.mat-mdc-tab.mdc-tab .mdc-tab__text-label{
  color: var(--on-background-grey);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--secondary) !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: var(--secondary) !important;
}

.mat-mdc-tab.mdc-tab {
  flex-grow: 0 !important;
  width: 165px;
  margin-bottom: 24px;
}

.mat-mdc-tab.mdc-tab:hover {
  background-color: var(--background-hover) !important;
}
.mat-mdc-tab.mdc-tab.mat-mdc-tab.mdc-tab--active {
  background-color: var(--background-hover) !important;
}
.mat-mdc-tab.mdc-tab.mat-mdc-tab.mdc-tab--pressed{
  background-color: var(--background-pressed) !important;
}

/*Overwrite colors of tab bar when being hovered or pressed:*/
.mat-mdc-tab-group, .mat-mdc-tab-nav-bar{
  --mat-tab-header-inactive-label-text-color: var(--secondary) !important;
  --mat-tab-header-inactive-focus-label-text-color: var(--secondary) !important;
  --mdc-tab-indicator-active-indicator-color: var(--secondary) !important;
  --mat-tab-header-active-ripple-color: var(--secondary) !important;
  --mat-tab-header-inactive-ripple-color: var(--secondary) !important;
}





